<!--调拨入库-->
<template>
    <div class="AllocateIn">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调入机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="单据状态">
                            <el-select placeholder="请选择" v-model="form.stockStatus">
                                <el-option v-for="e in meta.inEnums" :label="e.label" :value="e.code" :key="e.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.stock.allocateIn.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.stock.allocateIn.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="180" prop="code" v-if="showColumn('code')" sortable />
                <el-table-column
                    label="关联申请单号"
                    width="180"
                    prop="applyCode"
                    v-if="showColumn('applyCode')"
                    sortable
                />
                <el-table-column label="调入机构" width="160" prop="deptName" v-if="showColumn('deptName')" sortable />
                <el-table-column
                    label="调出机构"
                    width="160"
                    prop="outDeptName"
                    v-if="showColumn('outDeptName')"
                    sortable
                />
                <el-table-column
                    label="单据金额"
                    width="100"
                    prop="money"
                    key="money"
                    v-if="showColumn('money') && showInPrice()"
                    sortable
                    align="right"
                >
                    <template slot-scope="scope">
                        <span style="font-weight: bold">{{ scope.row.money | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据状态"
                    width="100"
                    prop="stockStatus"
                    key="stockStatus"
                    v-if="showColumn('stockStatus')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockInStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入库时间"
                    width="140"
                    prop="stockChangeTime"
                    v-if="showColumn('stockChangeTime')"
                    sortable
                />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" sortable />
                <el-table-column
                    label="创建时间"
                    width="140"
                    prop="createTime"
                    v-if="showColumn('createTime')"
                    sortable
                />

                <el-table-column
                    label="操作"
                    min-width="160"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.stock.allocateIn.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            :disabled="scope.row.stockStatus == 1"
                            @click="handleIn(scope.row)"
                            v-if="hasPrivilege('menu.stock.allocateIn.stock')"
                            >入库
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import MoneyUtils from 'js/MoneyUtils';
import EfPagination from 'components/EfPagination';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'AllocateIn',
    components: { EfEndDate, EfPagination, CheckTableShowColumnDialog, EfStartDate },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                stockStatus: '',
                page: 1,
                limit: Util.Limit,
            },
            total: 0,
            loading: false,
            tableData: [],
            url: {
                page: '/stock/allocate/in/page',
            },
            depts: [],
            meta: {
                reviewEnums: Util.reviewStatusEnum(true),
                inEnums: Util.stockInStatusEnum(true),
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedStoresAndRepos().then((rst) => {
            this.depts = rst;
            this.form.deptCode = this.depts[0].code;
            this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        money(data) {
            return MoneyUtils.formatMoney2(MoneyUtils.moneyToFour(data), 4, '.');
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        queryList() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params, (rst) => {
                this.total = rst.count;
                this.tableData = rst.data;
            });
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.stock.allocateIn.detail', ['库存管理', '调拨入库', '入库详情'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '调拨入库', '/stock/allocate/in/export', this.form, codes);
        },

        handleIn(row) {
            if (row.stockStatus != 0) return;
            UrlUtils.PostRemote(
                this,
                '/stock/allocate/in/in',
                {
                    codes: row.code,
                    message: '确认入库该单据么?入库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!',
                },
                null,
                () => {
                    this.$message.success('入库成功');
                    this.handleQuery();
                }
            );
        },
    },
};
</script>

<style scoped>
.AllocateIn .el-form-item {
    margin-bottom: 0;
}
</style>
